<template>
  <div id="data-list-list-view" class="data-list-container">
    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <new @add="addDataSideBar" :isSidebarActive="newSidebar"
         @closeSidebar="newSidebar= false"/>

    <new-composite :products="list" @add="addDataSideBar" :isSidebarActive="newCompositeSidebar"
                   @closeSidebar="newCompositeSidebar= false"/>

    <update @update="updateDateSideBar" :isSidebarActive="updateSidebar"
            @closeSidebar="updateSidebar= false" :data="sidebarData"/>

    <update-composite :products="list" @update="updateDateSideBar" :isSidebarActive="updateCompositeSidebar"
                      @closeSidebar="updateCompositeSidebar= false" :data="sidebarData"/>

    <detail :data="sidebarData" :isSidebarActive="detailSidebar"
            @closeSidebar="detailSidebar= false"/>

    <stocks :data="sidebarData" :isSidebarActive="showStocksSidebar"
            @closeSidebar="showStocksSidebar= false"/>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage"
              :data="list">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4">

            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="editProduct(selected)">
                            <span class="flex items-center">
                              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Editar</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="exportToExcel">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="exportToExcelTemplate">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar plantilla</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="$router.push('/warehouse/import/stocks')">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Actualizar stocks</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item v-if="selected" @click="detailSidebar= true; sidebarData = selected">
                            <span class="flex items-center">
                              <feather-icon icon="EyeIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Ver movimientos</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item v-if="selected" @click="showStocksSidebar= true; sidebarData = selected">
                            <span class="flex items-center">
                              <feather-icon icon="EyeIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Ver stock</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!-- Add new regular-->
          <div
              class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="newSidebar= true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar</span>
          </div>

          <!-- Add new composite product-->
          <div
              class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="newCompositeSidebar= true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar producto compuesto</span>
          </div>
        </div>

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Items per page -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ list.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : list.length }} de {{
                queriedItems
              }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>

              <vs-dropdown-item @click="itemsPerPage=10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=30">
                <span>30</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=40">
                <span>40</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <div class="con-input-search vs-table--search">
            <input v-model="search" placeholder="Buscar productos.." type="text"
                   class="input-search vs-table--search-input">
            <i class="vs-icon notranslate icon-scale material-icons null">search</i>
          </div>
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="sku">SKU</vs-th>
        <vs-th sort-key="numberPart">Nro de Parte</vs-th>
        <vs-th sort-key="description">Descripción</vs-th>
        <vs-th sort-key="price">Precio de vta. Referencial</vs-th>
        <vs-th sort-key="type">Tipo</vs-th>
        <vs-th sort-key="stock">Stock</vs-th>
        <vs-th>Estado</vs-th>
        <vs-th>Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.id.substr(0, 6).toUpperCase() }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.numberPart }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.description }}</p>
          </vs-td>

          <vs-td>
            <p>{{
                Intl.NumberFormat('en-IN', { style: 'currency', currency: 'PEN' }).format(tr.price)
              }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.type }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.stock }}</p>
          </vs-td>

          <vs-td>
            <vs-switch @input="switchState(tr)" v-model="tr.state"/>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon title="Editar" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="editProduct(tr)"/>
            <feather-icon title="Movimientos" class="ml-2" icon="EyeIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="detailSidebar = true, sidebarData= tr"/>
            <feather-icon title="Stock" class="ml-2" icon="PackageIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="showStocksSidebar = true, sidebarData= tr"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import New from './New'
import NewComposite from './NewComposite'
import Update from './Update'
import UpdateComposite from './UpdateComposite'
import Detail from './Detail'
import Stocks from './Stocks'

import { db } from '@/firebase/firebaseConfig'

const _ = require('lodash')

const algoliasearch = require('algoliasearch')

const client = algoliasearch('XQUSDVRT6D', 'f5a3a884ae2750149d9d5e052e447506')
const index = client.initIndex('products')

export default {
  name: 'ListProducts',
  components: {
    New,
    NewComposite,
    Update,
    UpdateComposite,
    Detail,
    Stocks
  },
  data () {
    return {
      itemsPerPage: 20,
      isMounted: false,
      list: [],
      initProgress: false,
      selected: null,
      filter: false,
      //

      // Data Sidebar
      newSidebar: false,
      newCompositeSidebar: false,
      updateSidebar: false,
      updateCompositeSidebar: false,
      detailSidebar: false,
      showStocksSidebar: false,
      sidebarData: {},
      // Export
      cellAutoWidth: true,
      selectedFormat: 'xlsx',

      // Search
      search: ''
    }
  },
  async created () {
    try {
      this.initProgress = true

      // Search products in algolia, if search is empty list preview
      let response = await index.search('', {
        hitsPerPage: 20,
      })
      response.hits.forEach((p) => {
        delete p._highlightResult
        this.list.push({
          ...p,
          id: p.objectID
        })
      })
      // End

    } catch (e) {
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted () {
    this.isMounted = true
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  watch: {
    async search (value) {
      if (value) {
        this.list = []
        // Search products in algolia
        let response = await index.search(value, {
          hitsPerPage: 40,
        })
        response.hits.forEach((p) => {
          delete p._highlightResult
          this.list.push({
            ...p,
            id: p.objectID
          })
        })
        // End
      } else {
        this.list = []
        // Search products in algolia
        let response = await index.search('', {
          hitsPerPage: 20,
        })
        response.hits.forEach((p) => {
          delete p._highlightResult
          this.list.push({
            ...p,
            id: p.objectID
          })
        })
        // End
      }
    }
  },
  methods: {
    /**
     *  Enabled and disabled products
     **/
    switchState (product) {
      let productRef = db.collection('products').doc(product.id)
      productRef.update({
        state: product.state
      }).then(() => {
        this.$vs.notify({
          color: 'success',
          title: 'Producto',
          text: 'La modificación fue aplicada.'
        })
      }).catch(() => {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      })
    },
    /**
     * Get stocks
     * */
    loadStocks () {
      return new Promise((resolve, rejected) => {
        db.collectionGroup('stocks').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let stock = {
              id: doc.id,
              ...doc.data()
            }
            this.stocks.push(stock)
          })
          resolve('ok')
        }).catch((error) => {
          rejected(error)
        })
      })
    },
    /**
     * Get movements
     * */
    loadMovements () {
      return new Promise((resolve, rejected) => {
        db.collectionGroup('movements').orderBy('createdAt', 'desc').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let movement = {
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate()
            }
            this.movements.push(movement)
          })
          console.log(this.movements[0])
          resolve('ok')
        }).catch((error) => {
          rejected(error)
        })
      })
    },
    /**
     * Add data of sidebar
     * */
    addDataSideBar (o) {
      this.list.unshift(o)
    },
    /**
     * Update data of sidebar
     * */
    updateDateSideBar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Exports to excel
     */
    async exportToExcel () {
      try {
        this.initProgress = true
        await this.loadStocks()
        await this.loadMovements()
        import('@/vendor/Export2Excel').then(excel => {
          const list = _.cloneDeep(this.list)
          list.forEach((i) => {
            i.local1 = 0
            const stocks = []
            // Search stock
            this.stocks.forEach((o) => {
              if (o.productId === i.id) {
                stocks.push(o)
              }
            })

            // End
            if (stocks.length !== 0) {
              // Surquillo
              const local1 = stocks.find((s) => s.id === 'qJATEgtu7wmFGNTz5iUu')
              if (local1) {
                i.local1 = local1.stock
              }
            }
            i.stock = i.local1
          })
          const headerVal = ['id', 'sku', 'description', 'type', 'stock']

          const headerTitle = ['ID', 'SKU', 'Descripción', 'Type', 'Stock']

          const data = this.formatJson(headerVal, list)
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: 'Listado de productos',
            autoWidth: this.cellAutoWidth,
            bookType: 'xlsx'
          })
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Export template
     */
    exportToExcelTemplate () {
      import('@/vendor/Export2Excel').then(excel => {
        const list = _.cloneDeep(this.list)
        const headerVal = ['id', 'sku', 'stock']
        const headerTitle = ['ID', 'SKU', 'Stock']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Template Importacion Masiva',
          autoWidth: this.cellAutoWidth,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    /**
     * Edit
     */
    editProduct (product) {
      if (product.type === 'Regular') {
        this.updateSidebar = true
        this.sidebarData = product
      } else {
        this.updateCompositeSidebar = true
        this.sidebarData = product
      }
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
